@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
  --body-font: "IBM Plex Sans", sans-serif;
  --text-15: 0.9375rem;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
}

.MuiTab-root{
  text-transform: none !important;
}

.max-container {
    max-width: 1440px
}

body {
  scrollbar-color: rgb(173, 191, 243) rgb(228, 235, 255);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 3px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(228, 235, 255);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(173, 191, 243);
  border-radius: 16px;
  border: 4px solid rgb(173, 191, 243);
}

::-webkit-scrollbar-button {
  display:none;
}

.MuiAutocomplete-root {
  /* width: -webkit-fill-available; */
  width: 100%;
 }

 .MuiTabs-indicator {
  background-color: #1d3679 !important
 }
